footer {
    background: var(--primary-50);
    border-top: 1px solid var(--gray-200);
}

footer .content {
    row-gap: 55px;
    padding-top: 60px;
    padding-bottom: 60px;
}

/* PAGE LINK AND NEWSLETTER*/

footer .content .tc-container {
    column-gap: 20px;
    align-items: flex-start;
}

footer .content .tc-container>.tc-side {
    row-gap: 32px;
    align-items: flex-start;
}

footer .content .tc-container>.tc-side>button img {
    width: 183.356px;
    height: 40px;
}

footer .content .tc-container>.news-side {
    flex: 1;
    max-width: 529px;
}

footer .content .tc-container>.news-side>p {
    max-width: 438px;
    font-weight: 500;
}

footer .content .tc-container form input {
    flex: 1;
    font-weight: 500;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid var(--gray-200);
    background: var(--shade-white);
}

footer .content .tc-container form input::placeholder {
    color: var(--gray-400, #9CA3AF);
}

footer .content .tc-container form button {
    padding: 14px 28px;
    border-radius: 100px;
    background: var(--primary-500, #A273FF);
}

footer .content .tc-container form button img {
    width: 18px;
    height: 18px;
}

footer .content .tc-container form button>span {
    color: var(--shade-white);
    font-weight: 500;
}


footer .content .tc-container>.tc-side .page-link>li a {
    font-weight: 500;
    white-space: nowrap;
}

/* INFO TC (COPYRIGHT END) */

footer .content .info-tc>button img {
    width: 123px;
    height: 27px;
}

footer .content .info-tc>button {
    display: none;
}

footer .content .info-tc {
    padding-top: 32px;
    padding-left: 25px;
    padding-right: 25px;
    border-top: 1px solid var(--gray-200);
}

footer .content .info-tc .social>li a {
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
}

footer .content .info-tc .social>li a img {
    width: 24px;
    height: 24px;
}

@media screen and (max-width:1150px) {
    footer .content .tc-container>.tc-side .page-link>li a span {
        display: none;
    }

    footer .content .tc-container form input {
        width: 150px;
    }
}

@media screen and (max-width:950px) {
    footer .content .tc-container {
        column-gap: 16px;
    }
    footer .content .tc-container>.tc-side .page-link {
        gap: 16px;
    }
    footer .content .tc-container form input {
        width: 140px;
    }
}

@media screen and (max-width:834px) {
    footer .content .tc-container {
        column-gap: 60px;
    }
    footer .content .tc-container>.tc-side .page-link {
        gap: 24px;
    }
    footer .content .tc-container>.tc-side .page-link {
        flex-direction: column;
    }

    footer .content .tc-container>.news-side {
        margin-top: 72px;
        max-width: 377px;
    }

    footer .content .tc-container>.news-side form {
        flex-direction: column;
    }

    footer .content .tc-container form input {
        width: 100%;
    }

    footer .content .tc-container form button {
        width: 100%;
        justify-content: center;
    }

    footer .content .info-tc {
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (max-width:550px) {
    footer .content {
        padding-top: 32px;
        padding-bottom: 32px;
    }

    footer .content .tc-container {
        flex-direction: column;
    }

    footer .content .tc-container {
        row-gap: 24px;
    }

    footer .content .tc-container>.tc-side {
        width: 100%;
        border-bottom: 1px solid var(--gray-200);
    }

    footer .content .tc-container form button>span {
        font-size: 12px;
    }

    footer .content .tc-container>.news-side>p {
        font-weight: 400;
    }

    footer .content .tc-container>.tc-side .page-link {
        padding-bottom: 24px;
    }

    footer .content .tc-container>.news-side {
        margin-top: 0;
        row-gap: 32px;
        max-width: 100%;
    }

    footer .content .tc-container>.news-side>p {
        max-width: 100%;
    }

    footer .content {
        row-gap: 32px;
    }

    footer .content .tc-container>.tc-side>button {
        display: none;
    }

    footer .content .info-tc>button {
        display: block;
    }

    footer .content .info-tc {
        flex-direction: column;
        row-gap: 32px;
    }

    footer .content .info-tc>span {
        text-align: center;
        order: 3;
    }
}