:root {
  --background: #fff;
  --primary-500: #A273FF;
  --primary-300: #C7ABFF;
  --primary-200: #DAC7FF;
  --primary-100: #ECE3FF;
  --primary-50: #F6F1FF;
  --shade-black: #1E1A21;
  --shade-white: #fff;
  --gray-800: #1F2937;
  --gray-600: #4B5563;
  --gray-400: #D1D5DB;
  --gray-300: #D1D5DB;
  --gray-200: #E5E7EB;
  --gray-100: #F3F4F6;
  --star-color: #FACC15;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: var(--gray-800);
}

ul,
li,
a {
  list-style: none;
  text-decoration: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flex {
  display: flex;
}

.flrow {
  display: flex;
  flex-direction: row;
}

.flcolm {
  display: flex;
  flex-direction: column;
}

.btw {
  justify-content: space-between;
}

.liner {
  align-items: center;
}

.addgap8 {
  gap: 8px;
}

.addgap16 {
  gap: 16px;
}

.addgap24 {
  gap: 24px;
}

button,
a {
  transition: 0.3s;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  padding-inline: 0;
  padding-block: 0;
}

button:hover,
a:hover {
  opacity: 0.7;
}

button:disabled:hover,
a:disabled:hover {
  opacity: 1;
  cursor: not-allowed;
}

input {
  outline: none;
  background: none;
}


.max-container {
  margin: 0 auto;
  padding-left: 80px;
  padding-right: 80px;
  max-width: 1440px;
}

.rlve {
  position: relative;
}

.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 1;
}

@media screen and (max-width:950px) {
  .max-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width:834px) {
  .max-container {
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media screen and (max-width:550px) {
  .max-container {
    padding-left: 16px;
    padding-right: 16px;
  }
}