@import url('https://fonts.googleapis.com/css2?family=ADLaM+Display&family=Inter:wght@400;500;600;700&family=Urbanist&display=swap');

@font-face {
  font-family: 'Mori Gothic';
  src: url('../../fonts/mori_gothic/MoriGothic-Bold.eot');
  src: local('Mori Gothic Bold'), local('MoriGothic-Bold'),
      url('../../fonts/mori_gothic/MoriGothic-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/mori_gothic/MoriGothic-Bold.woff2') format('woff2'),
      url('../../fonts/mori_gothic/MoriGothic-Bold.woff') format('woff'),
      url('../../fonts/mori_gothic/MoriGothic-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mori Gothic';
  src: url('../../fonts/mori_gothic/MoriGothic-Medium.eot');
  src: local('Mori Gothic Medium'), local('MoriGothic-Medium'),
      url('../../fonts/mori_gothic/MoriGothic-Medium.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/mori_gothic/MoriGothic-Medium.woff2') format('woff2'),
      url('../../fonts/mori_gothic/MoriGothic-Medium.woff') format('woff'),
      url('../../fonts/mori_gothic/MoriGothic-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mori Gothic Semi';
  src: url('../../fonts/mori_gothic/MoriGothic-SemiBold.eot');
  src: local('Mori Gothic Semi Bold'), local('MoriGothic-SemiBold'),
      url('../../fonts/mori_gothic/MoriGothic-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/mori_gothic/MoriGothic-SemiBold.woff2') format('woff2'),
      url('../../fonts/mori_gothic/MoriGothic-SemiBold.woff') format('woff'),
      url('../../fonts/mori_gothic/MoriGothic-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mori Gothic Extra';
  src: url('../../fonts/mori_gothic/MoriGothic-ExtraLight.eot');
  src: local('Mori Gothic Extra Light'), local('MoriGothic-ExtraLight'),
      url('../../fonts/mori_gothic/MoriGothic-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/mori_gothic/MoriGothic-ExtraLight.woff2') format('woff2'),
      url('../../fonts/mori_gothic/MoriGothic-ExtraLight.woff') format('woff'),
      url('../../fonts/mori_gothic/MoriGothic-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mori Gothic Normal';
  src: url('../../fonts/mori_gothic/MoriGothic-Normal.eot');
  src: local('Mori Gothic Normal'), local('MoriGothic-Normal'),
      url('../../fonts/mori_gothic/MoriGothic-Normal.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/mori_gothic/MoriGothic-Normal.woff2') format('woff2'),
      url('../../fonts/mori_gothic/MoriGothic-Normal.woff') format('woff'),
      url('../../fonts/mori_gothic/MoriGothic-Normal.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mori Gothic';
  src: url('../../fonts/mori_gothic/MoriGothic-Light.eot');
  src: local('Mori Gothic Light'), local('MoriGothic-Light'),
      url('../../fonts/mori_gothic/MoriGothic-Light.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/mori_gothic/MoriGothic-Light.woff2') format('woff2'),
      url('../../fonts/mori_gothic/MoriGothic-Light.woff') format('woff'),
      url('../../fonts/mori_gothic/MoriGothic-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mori Gothic';
  src: url('../../fonts/mori_gothic/MoriGothic-Regular.eot');
  src: local('Mori Gothic'), local('MoriGothic-Regular'),
      url('../../fonts/mori_gothic/MoriGothic-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/mori_gothic/MoriGothic-Regular.woff2') format('woff2'),
      url('../../fonts/mori_gothic/MoriGothic-Regular.woff') format('woff'),
      url('../../fonts/mori_gothic/MoriGothic-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}