.home-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 46px;
    filter: brightness(0.75);
    transition: 0.3s;
}

.coverLoader {
    border-radius: 46px;
    width: 100%;
    height: 100%;
    filter: brightness(0.75);
    object-fit: cover;
}

.home-video:hover {
    filter: brightness(0.6);
}

.home-video+.btn-action-video {
    opacity: 0;
}

.home-video:hover+.btn-action-video {
    opacity: 0.5 !important;
}

.home-video+.btn-action-video:hover {
    opacity: 1 !important;
}

@media screen and (max-width:550px) {
    .home-video {
        border-radius: 20px;
    }

    .coverLoader {
        border-radius: 20px;
    }

    .home-video+.btn-action-video {
        transform: translate(-50%, -50%) scale(0.6);
    }
}