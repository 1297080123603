.video-footer-section {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
    height: 600px;
}

@media screen and (max-width:550px) {
    .video-footer-section {
        height: auto;
        padding-top: 60px;
        padding-bottom: 80px;
        aspect-ratio: 274/300;
    }
}